import React from 'react'
import { createRoot } from 'react-dom/client'
import { Amplify } from 'aws-amplify'
import { AmplifyProvider } from '@aws-amplify/ui-react'
import App from './App'
import config from './aws-exports'
import './index.css'
import '@aws-amplify/ui-react/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import { persistor, store } from './store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'


Amplify.configure(config)
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<AmplifyProvider>
				<App />
			</AmplifyProvider>
		</PersistGate>
	</Provider>
)