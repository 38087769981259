import React from 'react'
import { Container } from 'react-bootstrap'
import AppNavbar from './AppNavbar'

const AppLayout = ({ children }) => {
  return (
    <React.Fragment>
        <AppNavbar />
        {children}
    </React.Fragment>
  )
}

export default AppLayout