import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import { Form } from "react-bootstrap";
import axios from "axios";
import Config from "../Config";
import { Input } from "@chakra-ui/react";

class AutoSuggestInput extends Component {
  constructor() {
    super();
    this.key = Config.AWS_KEY

    this.state = {
      value: "",
      suggestions: [],
      clearValue:false
    };
  }

  fetchLocations = async () => {
    if (this.state.value) {
    const { FETCH_SUGGETIONS } = Config.API_END_POINT

      const url = `${FETCH_SUGGETIONS}?key=${this.key}`;
      const response = await axios.post(url, {
        MaxResults: 4,
        Text: this.state.value,
        FilterCountries: "AUS",
      });
      const responseData = await response.data;

      const suggestions = responseData.Results
      // console.log(responseData)
      this.setState({
        suggestions,
      });
      return responseData;
    }
  };

  fetchPlace = async (placeId) => {
    if (placeId) {
      const { FETCH_LOCATIONS } = Config.API_END_POINT
      const url = `${FETCH_LOCATIONS}/${placeId}?key=${this.key}&language=en`;
      const response = await axios.get(url);
      const responseData = await response.data;

      const place = responseData.Place
      console.log(responseData)
      this.props.setPlace({
        Region: place.Region,
        Label:  place.Label,
        Long:   place.Geometry.Point[0],
        Lat:    place.Geometry.Point[1]
      })
      this.props.onChangeInput(place.Label)

      return responseData;
    }
  }

  // Define your data source (e.g., an array of suggestions or an API call)
  getSuggestions = async (value) => {
    // Your logic to fetch suggestions based on user input goes here
    return this.fetchLocations;
  };

  // Handle user input change
  onChange = (event, { newValue }) => {
    // console.log(this.state.value)
    this.setState({
      value: newValue,
    });
  };

  // Handle suggestion selection
  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      value: suggestion.Text
    });

    this.fetchPlace(suggestion.PlaceId)
  };

  // Render each suggestion
  renderSuggestion = (suggestion) => {
    return <div>{suggestion.Text}</div>;
  };

  onSuggestionsFetchRequested = ({ value }) => {

    //fetch locations
    this.fetchLocations(this.state.value)

    const suggestions = this.fetchLocations;
    this.setState({
      suggestions,
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.suggestions,
    });
  };

  // Render the input field
  renderInputComponent = (inputProps) => {
    return (
      <Input
        // borderRadius= "25px"
        // border={'1px solid'}
        type="text"
        id={this.props.title}
        value={this.props.title}
        name={this.props.title}
        placeholder={this.props.name}
        className="mb-3 mt-3 w-100 small"
        autoComplete="true"
        {...inputProps}
        disabled={this.props.disabled}
      />
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.resetForm !== this.props.resetForm && this.props.resetForm) {
      this.setState({ value: "" });
      this.props.setPlace({
        Region: "Victoria",
        Label: "Airport West, Melbourne, Victoria, AUS",
        Long: 144.88697,
        Lat: -37.71187,
      })
    }
  }


  render() {
    const { value, suggestions } = this.state;
    const { name } = this.props
    // console.log(name)
    const inputProps = {
      placeholder: name ,
      value,
      onChange: this.onChange,
    };

    return (
      <div className="autosuggest-container">
        <Autosuggest
          className="autosuggest-container"
          suggestions={suggestions}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={this.renderInputComponent}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          getSuggestionValue={this.getSuggestions}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        />
      </div>
    );
  }
}

export default AutoSuggestInput;
