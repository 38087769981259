import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as Yup from 'yup';
import axios from "axios";
import AppLayout from "../Layouts/AppLayout";
import config from "../Config";
import { Box, Flex, Input, Stack, Button, Text, Select, useToast } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { ErrorMessage, Field, Form, Formik } from "formik";
import AuthRoute from "../utils/AuthRoute";

const Register = () => {
  const { API_END_POINT } =
    config;
  const toast = useToast();
  const showToast = (title, status = 'success') => {
    toast({
      title,
      status, // 'success', 'error', 'warning', 'info'
      duration: 3000,
      isClosable: true,
      position: 'bottom-right',
      // description: 'This is a sample toast message.',
    });
  };

  React.useEffect(() => {

  }, []);
  const handleSubmitForm = (values, { setSubmitting, resetForm }) => {
    values = { ...values, Mobile: values.CountryCode + values.Mobile, AdditionalInfo: { MPTP: values.MPTP, NDIS: values.NDIS }, ContactType: 'rider' }
    console.log(values);
    //return true;
    // Handle form submission 
    const URL = `${API_END_POINT.API_URL}/rider`
    axios.post(URL, values)
      .then(res => {
        console.log('Response:', res.data);
        // reset message
        if (res.status == 200) {
          resetForm({ values: initialFormValues });
          showToast(res.data.Message);
        }
        else {
          showToast(res.data.Message);
        }

        setSubmitting(false);
      })
      .catch(error => {
        // show error message
        showToast('Some error occured', 'error');

        setSubmitting(false);
      });
  };

  const initialFormValues = {
    FirstName: '',
    LastName: '',
    Email: '',
    CountryCode: '+61',
    Mobile: '',
    MPTP: '',
    NDIS: '',
    Password: '',
    ConfirmPassword: ''
  }

  return (
    <AuthRoute>
      <AppLayout>
        <Container>
          <Row style={{ margin: "0px" }}>
            <Col xl={"12"} md={"12"} style={{ height: "90vh" }}>
              <Box my={10}>
                <Formik
                  initialValues={initialFormValues}
                  validationSchema={Yup.object().shape({
                    FirstName: Yup.string().required('Rider first name is required')
                      .matches(/^[A-Za-z\d\.'\-]{2,50}$/, 'Rider first name is invalid'),
                    LastName: Yup.string().required('Rider last name is required')
                      .matches(/^[A-Za-z\d\.'\-]{2,50}$/, 'Rider last name is invalid'),
                    Email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Rider email is invalid').required('Rider email is required'),
                    Mobile: Yup.string().required('Rider Mobile is required')
                      .matches(/^(04|4)\d{8,9}$/i, 'Rider Mobile number is invalid'),
                    CountryCode: Yup.string().required('Country code is required'),
                    MPTP: Yup.string().matches(/^[\d]{18}$/, 'Rider MPTP should be 18 digit no.'),
                    NDIS: Yup.string().matches(/^[\d]{9}$/, 'Rider NDIS should be 9 digit no.'),
                    Password: Yup.string().required('Password is required')
                      .test('passwordcheck',
                        'Password should match with confirm password.',
                        function (value) {
                          if (value === this.parent.ConfirmPassword) {
                            return true;
                          }
                        }
                      ),
                    ConfirmPassword: Yup.string().required('Password is required')
                  })}
                  onSubmit={handleSubmitForm}
                >
                  {({ isSubmitting, handleSubmit, values, setFieldValue, resetForm }) => (
                    <Form onSubmit={handleSubmit}>
                      <Stack
                        direction={'column'}
                        spacing={3}
                        //alignItems={"center"}
                        //justifyContent={"center"}
                        height={"100%"}
                      >
                        <Box display={{ sm: "block", lg: "flex", md: "flex" }}>

                          <Text mr="2"
                            width={["100%", "30%", "25%", "20%"]}
                            align={{ sm: "left", lg: "right", md: "right" }}>
                            Rider First Name:</Text>

                          <Box width={["100%", "70%", "75%", "80%"]}>
                            <Input
                              // borderRadius="25px"
                              type="text"
                              id="FirstName"
                              name="FirstName"
                              placeholder="First name"
                              className="w-100 small"
                              autoComplete="true"
                              value={values.FirstName}
                              onChange={e => setFieldValue('FirstName', e.target.value)}
                            />
                            <ErrorMessage name="FirstName" >
                              {(msg) => (
                                <Box mt={2}>
                                  <Text color="red">{msg}</Text>
                                </Box>
                              )}
                            </ErrorMessage>
                          </Box>
                        </Box>

                        <Box display={{ sm: "block", lg: "flex", md: "flex" }}>
                          <Text mr="2"
                            width={["100%", "30%", "25%", "20%"]}
                            align={{ sm: "left", lg: "right", md: "right" }}>
                            Rider Last Name:
                            </Text>
                            <Box width={["100%", "70%", "75%", "80%"]}>
                            <Input
                              // borderRadius="25px"
                              type="text"
                              id="LastName"
                              name="LastName"
                              placeholder="Last name"
                              className="small"
                              autoComplete="true"
                              value={values.LastName}
                              onChange={e => setFieldValue('LastName', e.target.value)}
                            />
                            <ErrorMessage name="LastName" >
                              {(msg) => (
                                <Box mt={2}>
                                  <Text color="red">{msg}</Text>
                                </Box>
                              )}
                            </ErrorMessage>
                          </Box>
                        </Box>

                        <Box display={{ sm: "block" ,lg: "flex", md: "flex"}}>

                          <Text mr="2"
                          width={["100%", "30%", "25%", "20%"]}
                          align={{ sm: "left" ,lg: "right", md: "right"}}>
                            Rider Mobile:
                            </Text>

                            <Box width={["100%", "70%", "75%", "80%"]}>
                            <Stack direction={'row'}>

                              <Box w={'20%'}>
                                <Field name="CountryCode">
                                  {({ field }) => (
                                    <Select {...field} placeholder="Select Country Code">
                                      {/* Add options for country codes */}
                                      <option value="+61" selected>+61</option>
                                      <option value="+91">+91</option>
                                      {/* Add more country codes as needed */}
                                    </Select>
                                  )}
                                </Field>
                              </Box>
                              <Box w={'80%'}>
                                <Input
                                  //borderRadius="25px"
                                  type="tel"
                                  id="Mobile"
                                  name="Mobile"
                                  variant='outline'
                                  placeholder="Mobile No"
                                  className="w-100 small"
                                  autoComplete="true"
                                  value={values.Mobile}
                                  onChange={e => setFieldValue('Mobile', e.target.value)}
                                />
                                Mobile number should start with 04
                              </Box>
                            </Stack>
                            <Stack direction={'row'}>

                              <ErrorMessage name="CountryCode" >
                                {(msg) => (
                                  <Box mt={2}>
                                    <Text color="red">{msg}</Text>
                                  </Box>
                                )}
                              </ErrorMessage>
                              <ErrorMessage name="Mobile" >
                                {(msg) => (
                                  <Box mt={2}>
                                    <Text color="red">{msg}</Text>
                                  </Box>
                                )}
                              </ErrorMessage>
                            </Stack>
                          </Box>
                        </Box>

                        <Box display={{ sm: "block" ,lg: "flex", md: "flex"}}>
                        <Text mr="2"
                          width={["100%", "30%", "25%", "20%"]}
                          align={{ sm: "left" ,lg: "right", md: "right"}}>
                          Rider Email:
                          </Text>
                          <Box width={["100%", "70%", "75%", "80%"]}>
                            <Input type="email" value={values.Email} onChange={e => setFieldValue('Email', e.target.value)} id="Email" name="Email" placeholder="Email" variant='outline' className="w-100 small" autoComplete="true" />
                            <ErrorMessage name="Email" >
                              {(msg) => (
                                <Box mt={2}>
                                  <Text color="red">{msg}</Text>
                                </Box>
                              )}
                            </ErrorMessage>
                          </Box>
                        </Box>

                        <Box display={{ sm: "block" ,lg: "flex", md: "flex"}}>

                          <Text mr="2"
                          width={["100%", "30%", "25%", "20%"]}
                          align={{ sm: "left" ,lg: "right", md: "right"}}>Rider MPTP:</Text>
                          <Box width={["100%", "70%", "75%", "80%"]}>
                            <Input
                              // borderRadius="25px"
                              type="text"
                              id="MPTP"
                              name="MPTP"
                              placeholder="MPTP (optional)"
                              className="w-100 small"
                              autoComplete="true"
                              value={values.MPTP}
                              onChange={e => setFieldValue('MPTP', e.target.value)}
                            />
                            <ErrorMessage name="MPTP" >
                              {(msg) => (
                                <Box mt={2}>
                                  <Text color="red">{msg}</Text>
                                </Box>
                              )}
                            </ErrorMessage>
                          </Box>
                        </Box>
                        <Box display={{ sm: "block" ,lg: "flex", md: "flex"}}>
                        <Text mr="2"
                          width={["100%", "30%", "25%", "20%"]}
                          align={{ sm: "left" ,lg: "right", md: "right"}}>
                          Rider NDIS:
                          </Text>
                          <Box width={["100%", "70%", "75%", "80%"]}>
                            <Input
                              // borderRadius="25px"
                              type="text"
                              id="NDIS"
                              name="NDIS"
                              placeholder="NDIS (optional)"
                              className="w-100 small"
                              autoComplete="true"
                              value={values.NDIS}
                              onChange={e => setFieldValue('NDIS', e.target.value)}
                            />
                            <ErrorMessage name="NDIS" >
                              {(msg) => (
                                <Box mt={2}>
                                  <Text color="red">{msg}</Text>
                                </Box>
                              )}
                            </ErrorMessage>
                          </Box>
                        </Box>
                        <Box display={{ sm: "block" ,lg: "flex", md: "flex"}}>
                          <Text mr="2"
                          width={["100%", "30%", "25%", "20%"]}
                          align={{ sm: "left" ,lg: "right", md: "right"}}>Password:</Text>
                          <Box width={["100%", "70%", "75%", "80%"]}>
                            <Input
                              // borderRadius="25px"
                              type="password"
                              id="Password"
                              name="Password"
                              placeholder="Minimum 8 characters with 1 Capital, 1 number, 1 special character"
                              className="w-100 small"
                              autoComplete="true"
                              value={values.Password}
                              onChange={e => setFieldValue('Password', e.target.value)}
                            />
                            <ErrorMessage name="Password" >
                              {(msg) => (
                                <Box mt={2}>
                                  <Text color="red">{msg}</Text>
                                </Box>
                              )}
                            </ErrorMessage>
                          </Box>
                        </Box>
                        <Box display={{ sm: "block" ,lg: "flex", md: "flex"}}>
                        <Text mr="2"
                          width={["100%", "30%", "25%", "20%"]}
                          align={{ sm: "left" ,lg: "right", md: "right"}}>Confirm Password:</Text>
                          <Box width={["100%", "70%", "75%", "80%"]}>
                            <Input
                              // borderRadius="25px"
                              type="password"
                              id="ConfirmPassword"
                              name="ConfirmPassword"
                              placeholder="Minimum 8 characters with 1 Capital, 1 number, 1 special character"
                              className="w-100 small"
                              autoComplete="true"
                              value={values.ConfirmPassword}
                              onChange={e => setFieldValue('ConfirmPassword', e.target.value)}
                            />
                            <ErrorMessage name="ConfirmPassword" >
                              {(msg) => (
                                <Box mt={2}>
                                  <Text color="red">{msg}</Text>
                                </Box>
                              )}
                            </ErrorMessage>
                          </Box>
                        </Box>

                        <Stack
                          spacing={5}
                          direction={'row'}
                          justifyContent={'center'}
                          style={{ marginTop: "10px" }}
                        >

                          <Button type="submit" bgColor="#000" color='#fff' size='sm' disabled={isSubmitting} onClick={handleSubmit} colorScheme='teal'>
                            Sign Up
                          </Button>

                          <Button
                            bgColor="#000"
                            color='#fff'
                            size="sm"
                            type="button"
                            colorScheme='teal'
                            onClick={() => {
                              resetForm({ values: initialFormValues });
                            }}
                          >
                            Clear Form
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Col>

          </Row>
        </Container>
      </AppLayout>
    </AuthRoute>
  );
};

export default Register;
