import React from "react";
import * as Yup from 'yup';
import axios from "axios";
import AppLayout from "../Layouts/AppLayout";
import AuthRoute from "../utils/AuthRoute";
import config from "../Config";
import { Box, Input, Stack, Button, Text, useToast} from "@chakra-ui/react";
import { ErrorMessage, Form, Formik } from "formik";

const ChangePassword = () => {
    const {  API_END_POINT } =
    config;
  const toast = useToast();
  const showToast = (title,status = 'success') => {
    toast({
      title,
      status, // 'success', 'error', 'warning', 'info'
      duration: 3000, 
      isClosable: true, 
      position: 'bottom-right', 
      // description: 'This is a sample toast message.',
    });
  };

  React.useEffect(() => {
    
  }, []);
  const handleSubmitForm = (values, { setSubmitting, resetForm }) => {
    values = {...values}
    console.log(values);
    //return true;
    // Handle form submission 
     const URL = `${API_END_POINT.API_URL}/forgetPassword`
     axios.post(URL,values)
         .then(res => { 
                console.log('Response:',res.data);
            // reset message
               if(res.status===200){
                    resetForm({values: initialFormValues}); 
                    showToast(res.data.Message); 
               }
               else{
                    showToast(res.data.Errors.join('<br/>')); 
               }

            setSubmitting(false); 
           })
         .catch(error => { 
            // show error message
            showToast('Some error occured','error'); 
            
            setSubmitting(false); 
         });
  };

  const initialFormValues = {
    Email:''
  }

  return (
    <AuthRoute>
    <AppLayout>
      <Box width={["100%", "70%", "75%", "80%"]} margin={"0 auto"}>
        
        <Box my={10}>
            <Formik
              initialValues={initialFormValues}
                validationSchema={Yup.object().shape({  
                  Email: Yup.string().required('Email id is required').email('Invalid email')
                })}
                onSubmit={handleSubmitForm}
              >
                { ({isSubmitting, handleSubmit, values, setFieldValue, resetForm}) => (
                <Form onSubmit={handleSubmit}>
                    <Stack
                        direction={'column'}
                        spacing={0}
                        alignItems={"center"}
                        justifyContent={"center"}
                        height={"100%"}
                        
                    >
                  <Box display={{ sm: "block", lg: "flex", md: "flex" }}
                    bgColor={'#f9f9f9'}
                    padding={"30px"} 
                    width={["90%", "60%", "50%", "50%"]}
                    >
                      <Text mr="2" 
                      width={["100%", "30%", "25%", "20%"]}
                      align="left">Email:</Text>
                        <Box w={'80%'}>
                            <Input
                                // borderRadius="25px"
                                type="email"
                                id="Email"
                                name="Email"
                                placeholder="Enter Email"
                                className="w-100 small"
                                autoComplete="true"
                                value={values.Email} 
                                onChange={e => setFieldValue('Email',e.target.value)}
                            />
                            <ErrorMessage name="Email" >
                            {(msg) => (
                                <Box mt={2}>
                                <Text color="red">{msg}</Text>
                                </Box>
                            )}
                            </ErrorMessage>
                        </Box>
                    </Box>
                      <Stack
                        spacing={5}
                        direction={'row'}
                        justifyContent={'center'}
                        style={{marginTop:"30px"}}
                      >

                        <Button type="submit" bgColor="#000" color='#fff' size='lg' disabled={isSubmitting} onClick={handleSubmit} colorScheme='teal'>
                          Submit
                        </Button>
                      </Stack>
                     
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Box>
          
      </Box>
    </AppLayout>
    </AuthRoute>
  );
};
export default ChangePassword;