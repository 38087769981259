import React from 'react';
import { Link } from 'react-router-dom';
import {
  Flex,
  Box,
  Heading,
  useColorMode,
  useColorModeValue,
  IconButton,
  useDisclosure,
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { SunIcon, MoonIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UserSelector, logout } from '../store/features/UserSlice';

const AppNavbar = () => {
  const dispatch = useDispatch()

  const { colorMode, toggleColorMode } = useColorMode();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const bgColor = useColorModeValue('#0efbef', 'gray.900');
  const textColor = useColorModeValue('#000', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isAuthenticated, user } = useSelector(UserSelector);

  const handleAvatarClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    dispatch(logout())
    setIsDropdownOpen(false);
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      padding="1rem"
      bg={bgColor}
      color={textColor}
      flexWrap="wrap"
    >
      <Box>
        <Heading as="h1" size="md">
          Lumiride
        </Heading>
      </Box>
      <Box display={{ base: 'block', md: 'none' }} onClick={isOpen ? onClose : onOpen}>
        <IconButton
          aria-label="Toggle color Dark/Light mode"
          icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
          onClick={toggleColorMode}
          variant="ghost"
        />
        <IconButton
          variant="ghost"
          aria-label="Toggle navigation"
          icon={<HamburgerIcon />}
        />
      </Box>
      <Box
        display={{ base: isOpen ? 'block' : 'none', md: 'flex' }}
        width={{ base: 'full', md: 'auto' }}
        justifyContent={{ base: 'center', md: 'flex-end' }}
        alignItems="center"
        flexGrow={1}
        mt={{ base: 4, md: 0 }}
      >
      { isAuthenticated ? 
        <React.Fragment>
          <Menu>
            <MenuButton onClick={handleAvatarClick}>
              <Avatar size='sm' color={'white'} bg={'black'} name={`${user.FirstName} ${user.LastName}`} />
            </MenuButton>
            {isDropdownOpen && (
              <MenuList>
               <Link to='/change-password'><MenuItem>Change Password</MenuItem></Link> 
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MenuList>
            )}
          </Menu>
        </React.Fragment> :''

        // <React.Fragment>
        //   <Box mb={{ base: 4, md: 0 }} mr={{ base: 0, md: 4 }}>
        //   <Link to='/login' color="{textColor}">Login</Link>
        //   </Box>
        //   <Box mb={{ base: 4, md: 0 }} mr={{ base: 0, md: 4 }}>
        //   <Link to='/signup' color={textColor}>Signup</Link>
        //   </Box>
        // </React.Fragment>

      }
       
      </Box>
    </Flex>
  );
};

export default AppNavbar;
