import React from "react";
import * as Yup from 'yup';
import AppLayout from "../Layouts/AppLayout";
import { Box, Input, Stack, Button, Text, useToast} from "@chakra-ui/react";
import { ErrorMessage, Form, Formik } from "formik";
import { useLoginMutation } from "../store/features/AuthApi";
import { useDispatch } from "react-redux";
import { setUser } from "../store/features/UserSlice";
import { useNavigate,
  Link } from "react-router-dom";
import AuthRoute from "../utils/AuthRoute";

const Login = () => {
  const [login, { isLoading, isError }] = useLoginMutation();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const showToast = (title,status = 'success') => {
    toast({
      title,
      status, // 'success', 'error', 'warning', 'info'
      duration: 3000, 
      isClosable: true, 
      position: 'bottom-right', 
      // description: 'This is a sample toast message.',
    });
  };

  React.useEffect(() => {
    
  }, []);
  const handleSubmitForm = async (values, { setSubmitting, resetForm }) => {
    //return true;
    // Handle form submission 

    try {
      const response = await login(values);
      if (response.data) {
        resetForm({ values: initialFormValues });
        dispatch(setUser(response.data));
        showToast("Login successfully");
        navigate('/')
      } else {
        console.log(response.data)
        showToast(response.data.msg);
      }
    } catch (error) {
      showToast('Some error occurred', 'error');
    } finally {
      setSubmitting(false);
    }

  };

  const initialFormValues = {
    EmailId: '',
    Password: '',
  }

  return (
    <AuthRoute>
      <AppLayout>
        <Box width={["100%", "70%", "75%", "80%"]} margin={"0 auto"}>
          
              <Box my={10}>
                <Formik
                initialValues={initialFormValues}
                  validationSchema={Yup.object().shape({ 
                    EmailId: Yup.string().email('Invalid email').required('Email is required'),
                    Password:Yup.string().required('Password is required')
                  })}
                  onSubmit={handleSubmitForm}
                >
                  { ({isSubmitting, handleSubmit, values, setFieldValue, resetForm}) => (
                    <Form onSubmit={handleSubmit}>
                      <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems={"center"}
                          justifyContent={"center"}
                          height={"100%"}
                          
                      >
                      <Box display={{ sm: "block", lg: "flex", md: "flex" }}
                      bgColor={'#f9f9f9'}
                      padding={"30px"} 
                      width={["90%", "60%", "50%", "50%"]}
                      >
                        <Text mr="2" 
                        width={["100%", "30%", "25%", "20%"]}
                        align="left">
                          Email:
                        </Text>
                        <Box width={["100%", "70%", "75%", "80%"]}>
                          <Input type="email" value={values.EmailId} onChange={e => setFieldValue('EmailId',e.target.value)} id="EmailId" name="EmailId" placeholder="Email" variant='outline' className="w-100 small" autoComplete="true" />
                          <ErrorMessage name="EmailId" >
                            {(msg) => (
                              <Box mt={2}>
                                <Text color="red">{msg}</Text>
                              </Box>
                            )}
                          </ErrorMessage>
                        </Box>
                      </Box>

                    <Box display={{ sm: "block", lg: "flex", md: "flex" }}
                      bgColor={'#f9f9f9'}
                      padding={"30px"} 
                      width={["90%", "60%", "50%", "50%"]}
                      >
                        <Text mr="2" 
                        width={["100%", "30%", "25%", "20%"]}
                        align="left">Password:</Text>
                          <Box w={'80%'}>
                          <Input
                              // borderRadius="25px"
                              type="password"
                              id="Password"
                              name="Password"
                              placeholder="Enter Password"
                              className="w-100 small"
                              autoComplete="true"
                              value={values.Password} 
                              onChange={e => setFieldValue('Password',e.target.value)}
                          />
                          <ErrorMessage name="Password" >
                            {(msg) => (
                              <Box mt={2}>
                                <Text color="red">{msg}</Text>
                              </Box>
                            )}
                          </ErrorMessage>
                      </Box>
                  </Box>
                  <Link to="/forget-password">Forget Password</Link>
                        <Stack
                          spacing={5}
                          direction={'row'}
                          justifyContent={'center'}
                          style={{marginTop:"30px"}}
                        >

                          <Button type="submit" bgColor="#000" color='#fff' size='lg' disabled={isSubmitting} onClick={handleSubmit} colorScheme='teal'>
                            Login
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            
        </Box>
      </AppLayout>
    </AuthRoute>
  );
};
export default Login;