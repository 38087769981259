import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../Config';
const {  API_END_POINT } = config;

const API_URL = API_END_POINT.API_URL;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (values) => ({
        url: '/login',
        method: 'POST',
        body: values,
      }),
    }),
  }),
});

export const { useLoginMutation } = authApi;
