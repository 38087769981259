// PrivateRoute.js
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserSelector } from '../store/features/UserSlice';
import React from 'react';

const AuthRoute = ({ children }) => {

  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(UserSelector);

  React.useEffect(() => {
    // Check if the user is authenticated
    if (isAuthenticated) {
      // Redirect the user to the login page
      navigate('/');
    }
  }, [isAuthenticated]);

  return !isAuthenticated ? <>{children}</> : null;
};

export default AuthRoute;