import React from "react";
import {
    useSearchParams,
    useNavigate
  } from "react-router-dom"
import * as Yup from 'yup';
import axios from "axios";
import AppLayout from "../Layouts/AppLayout";
import config from "../Config";
import { Box, Input, Stack, Button, Text, useToast} from "@chakra-ui/react";
import { ErrorMessage, Form, Formik } from "formik";

const ResetPassword = () => {
    const navigate = useNavigate();
    const {  API_END_POINT } =
    config;
  const toast = useToast();
  const [queryParameters] = useSearchParams()
  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const showToast = (title,status = 'success') => {
    toast({
      title,
      status, // 'success', 'error', 'warning', 'info'
      duration: 3000, 
      isClosable: true, 
      position: 'bottom-right', 
      // description: 'This is a sample toast message.',
    });
  };

  React.useEffect(() => {
    
  }, []);
  const handleSubmitForm = (values, { setSubmitting, resetForm }) => {
    values = {...values, Email : queryParameters.get("email"),Key : queryParameters.get("key")}
    console.log(values);
    //return true;
    // Handle form submission 
     const URL = `${API_END_POINT.API_URL}/resetPassword`
     axios.post(URL,values)
         .then(res => { 
                console.log('Response:',res.data);
            // reset message
               if(res.status===200){
                    resetForm({values: initialFormValues}); 
                    showToast(res.data.Message);
                    navigate('/login') 

               }
               else{
                    showToast(res.data.Errors.join('<br/>')); 
               }

            setSubmitting(false); 
           })
         .catch(error => { 
            // show error message
            showToast('Some error occured','error'); 
            
            setSubmitting(false); 
         });
  };

  const initialFormValues = {
    
    NewPassword: '',
    ConfirmPassword:''
  }
  console.log("Rest",initialFormValues);
  return (
    <AppLayout>
      <Box width={["100%", "70%", "75%", "80%"]} margin={"0 auto"}>
        
        <Box my={10}>
            <Formik
              initialValues={initialFormValues}
                validationSchema={Yup.object().shape({ 
                 NewPassword:Yup.string().required('New Password is required')
                  .matches(PASSWORD_REGEX, 'New password must contain min 8 chars and should include at least one upper case character (A-Z), one lower case character (a-z), one number (0-9), one special char(*^% etc.)'),
                  ConfirmPassword: Yup.string().oneOf([Yup.ref('NewPassword')], "confirmPassword doesn't match the password")
                })}
                onSubmit={handleSubmitForm}
              >
                { ({isSubmitting, handleSubmit, values, setFieldValue, resetForm}) => (
                <Form onSubmit={handleSubmit}>
                    <Stack
                        direction={'column'}
                        spacing={0}
                        alignItems={"center"}
                        justifyContent={"center"}
                        height={"100%"}
                        
                    >
                    <Box display={{ sm: "block", lg: "flex", md: "flex" }}
                    bgColor={'#f9f9f9'}
                    padding={"30px"} 
                    width={["90%", "60%", "50%", "50%"]}
                    >
                      <Text mr="2" 
                      width={["100%", "30%", "25%", "20%"]}
                      align="left">New Password:</Text>
                        <Box w={'80%'}>
                            <Input
                                // borderRadius="25px"
                                type="password"
                                id="NewPassword"
                                name="NewPassword"
                                placeholder="Minimum 8 characters with 1 Capital, 1 number, 1 special character"
                                className="w-100 small"
                                autoComplete="true"
                                value={values.NewPassword} 
                                onChange={e => setFieldValue('NewPassword',e.target.value)}
                            />
                            <ErrorMessage name="NewPassword" >
                            {(msg) => (
                                <Box mt={2}>
                                <Text color="red">{msg}</Text>
                                </Box>
                            )}
                            </ErrorMessage>
                     </Box>
                    </Box>
                    <Box display={{ sm: "block", lg: "flex", md: "flex" }}
                    bgColor={'#f9f9f9'}
                    padding={"30px"} 
                    width={["90%", "60%", "50%", "50%"]}
                    >
                      <Text mr="2" 
                      width={["100%", "30%", "25%", "20%"]}
                      align="left">Confirm Password:</Text>
                        <Box w={'80%'}>
                            <Input
                                // borderRadius="25px"
                                type="password"
                                id="ConfirmPassword"
                                name="ConfirmPassword"
                                placeholder="Minimum 8 characters with 1 Capital, 1 number, 1 special character"
                                className="w-100 small"
                                autoComplete="true"
                                value={values.ConfirmPassword} 
                                onChange={e => setFieldValue('ConfirmPassword',e.target.value)}
                            />
                            <ErrorMessage name="ConfirmPassword" >
                            {(msg) => (
                                <Box mt={2}>
                                <Text color="red">{msg}</Text>
                                </Box>
                            )}
                            </ErrorMessage>
                        </Box>
                    </Box>
                    

                      <Stack
                        spacing={5}
                        direction={'row'}
                        justifyContent={'center'}
                        style={{marginTop:"30px"}}
                      >

                        <Button type="submit" bgColor="#000" color='#fff' size='lg' disabled={isSubmitting} onClick={handleSubmit} colorScheme='teal'>
                          Submit
                        </Button>
                      </Stack>
                     
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Box>
          
      </Box>
    </AppLayout>
  );
};
export default ResetPassword;