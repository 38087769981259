import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import "@aws-amplify/ui-react-geo/styles.css";
import awsExports from "./aws-exports";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-datepicker/dist/react-datepicker.css';
import "./App.css";
import Register from "./Pages/Register";
import Login from "./Pages/Login";
import ChangePassword from "./Pages/ChangePassword";
import ResetPassword from "./Pages/ResetPassword";
import ForgetPassword from "./Pages/ForgetPassword";
import BookingRequest from "./Pages/BookingRequest";
import { ChakraProvider } from "@chakra-ui/react";


Amplify.configure(awsExports);
const App = () => {


  // const toggleTheme = () => {
  //   setUseDarkTheme(!useDarkTheme);
  // };

  return (
    <ChakraProvider>
      <Router>
        <Routes>
            <Route
                exact
                path="/"
                element={<BookingRequest />}
            ></Route>
            <Route
                exact
                path="/signup"
                element={<Register />}
            ></Route>
            <Route
                exact
                path="/login"
                element={<Login />}
            ></Route>
             <Route
                exact
                path="/change-password"
                element={<ChangePassword />}
            ></Route>
            <Route
                exact
                path="/forget-password"
                element={<ForgetPassword />}
            ></Route>
            <Route
                exact
                path="/reset-password"
                element={<ResetPassword />}
            ></Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
