import React from "react";
import { withIdentityPoolId } from "@aws/amazon-location-utilities-auth-helper";
import mapboxgl from "maplibre-gl";
import { Col, Container, Row } from "react-bootstrap";
import * as Yup from 'yup';
import axios from "axios";
import DatePicker from 'react-datepicker';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

import AppLayout from "../Layouts/AppLayout";
import AutoSuggestInput from "../Components/AutoSuggestInput";
import config from "../Config";
import { Box, Flex, Input, InputGroup, InputRightElement, Stack, Button, Text, Select, useToast, Alert, AlertIcon, Textarea, RadioGroup, Radio } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { UserSelector, logout } from '../store/features/UserSlice';
import { color } from "framer-motion";
const BookingRequest = () => {
  const { AWS_KEY, AWS_POOL_ID, API_END_POINT, Distance_Unit, Travel_Mode } =
    config;
  // console.log(API_END_POINT);
  const [authHelper, setAuthHelper] = React.useState(null);
  const [place, setPlace] = React.useState({
    Region: "Victoria",
    Label: "Airport West, Melbourne, Victoria, AUS",
    Long: 144.88697,
    Lat: -37.71187,
  });
  const [destination, SetDestination] = React.useState(null);
  const [MapRouteData, setMapRouteData] = React.useState(null);
  const { isAuthenticated, user } = useSelector(UserSelector);
  const toast = useToast();
  const showToast = (title, status = 'success') => {
    toast({
      title,
      status, // 'success', 'error', 'warning', 'info'
      duration: 3000,
      isClosable: true,
      position: 'bottom-right',
      // description: 'This is a sample toast message.',
    });
  };

  React.useEffect(() => {
    getAuth();
  }, []);

  React.useEffect(() => {
    if (authHelper) {
      const map_mobile = new mapboxgl.Map({
        container: "map",
        // Initial map centerpoint
        center: [place.Long, place.Lat],
        // Initial zoom level
        zoom: 4,
        style: `${API_END_POINT.LOAD_MAP}`, // Or your custom MapLibre style URL
        hash: true,
        // Provides options required to make requests to Amazon Location
        ...authHelper.getMapAuthenticationOptions(),
      });

      // Add navigation controls
      map_mobile.addControl(new mapboxgl.NavigationControl(), "bottom-right");

      // Configure a new popup
      const popup_mobile = new mapboxgl.Popup({
        offset: 35,
      }).setHTML(`<h5>${place.Region}</h5><p>${place.Label}</p>`);

      // Add a new marker and popup
      map_mobile.flyTo({ center: [place.Long, place.Lat], zoom: 13 });
      new mapboxgl.Marker()
        .setLngLat([place.Long, place.Lat])
        .setPopup(popup_mobile)
        .addTo(map_mobile);

      if (destination) {
        const destinationPopup_mobile = new mapboxgl.Popup({
          offset: 35,
        }).setHTML(
          `<h5>${destination.Region}</h5>
          <p>${destination.Label}</p>`
        );
        map_mobile.flyTo({ center: [destination.Long, destination.Lat], zoom: 13 });
        // destination marker
        new mapboxgl.Marker()
          .setLngLat([destination.Long, destination.Lat])
          .setPopup(destinationPopup_mobile)
          .addTo(map_mobile);
      }
      if (MapRouteData) {
        console
          .log
          //  MapRouteData.Legs[0].Geometry.LineString.map((position)=>position)
          ();
        // add lines

        map_mobile.on("load", () => {
          // Add a data source containing GeoJSON data.
          map_mobile.addSource("route", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: MapRouteData.Legs[0].Geometry.LineString.map(
                  (position) => position
                ),
              },
            },
          });

          // Add a new layer to visualize the line.
          map_mobile.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#00b0ff",
              "line-width": 5,
            },
          });
        });
      }
      const map = new mapboxgl.Map({
        container: "map",
        // Initial map centerpoint
        center: [place.Long, place.Lat],
        // Initial zoom level
        zoom: 4,
        style: `${API_END_POINT.LOAD_MAP}`, // Or your custom MapLibre style URL
        hash: true,
        // Provides options required to make requests to Amazon Location
        ...authHelper.getMapAuthenticationOptions(),
      });

      // Add navigation controls
      map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

      // Configure a new popup
      const popup = new mapboxgl.Popup({
        offset: 35,
      }).setHTML(`<h5>${place.Region}</h5><p>${place.Label}</p>`);

      // Add a new marker and popup
      map.flyTo({ center: [place.Long, place.Lat], zoom: 13 });
      new mapboxgl.Marker()
        .setLngLat([place.Long, place.Lat])
        .setPopup(popup)
        .addTo(map);

      if (destination) {
        const destinationPopup = new mapboxgl.Popup({
          offset: 35,
        }).setHTML(
          `<h5>${destination.Region}</h5>
          <p>${destination.Label}</p>`
        );
        map.flyTo({ center: [destination.Long, destination.Lat], zoom: 13 });
        // destination marker
        new mapboxgl.Marker()
          .setLngLat([destination.Long, destination.Lat])
          .setPopup(destinationPopup)
          .addTo(map);
      }
      if (MapRouteData) {
        console
          .log
          //  MapRouteData.Legs[0].Geometry.LineString.map((position)=>position)
          ();
        // add lines

        map.on("load", () => {
          // Add a data source containing GeoJSON data.
          map.addSource("route", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: MapRouteData.Legs[0].Geometry.LineString.map(
                  (position) => position
                ),
              },
            },
          });

          // Add a new layer to visualize the line.
          map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#00b0ff",
              "line-width": 5,
            },
          });
        });
      }

      return () => map.remove();
    }
  }, [authHelper, place, destination, MapRouteData, API_END_POINT]);

  React.useEffect(() => {
    if (place && destination) {
      GetRoute()
    }
  }, [place, destination]);

  const getAuth = async () => {
    try {
      await withIdentityPoolId(AWS_POOL_ID)
        .then((authclient) => {
          setAuthHelper(authclient);
        })
        .catch((err) => console.log(err));

      // return client
    } catch (error) {
      console.error(error, "auth error");
      return null;
    }
  };

  const [selectedDateTime, setSelectedDateTime] = React.useState(null);
  const [BookingId, setBookingId] = React.useState(null);



  const convertSecondsToTime = (inputSeconds) => {
    const hours = Math.floor(inputSeconds / 3600);
    const minutes = Math.floor((inputSeconds % 3600) / 60);
    const seconds = inputSeconds % 60;

    return `${hours} Hrs, ${minutes} Min, ${seconds} Sec`;
  };

  const GetRoute = async () => {
    const RouteUrl = `${API_END_POINT.ROUTE_CALCULATION}?key=${AWS_KEY}`;
    const response = await axios.post(RouteUrl, {
      DeparturePosition: [place.Long, place.Lat],
      DestinationPosition: [destination.Long, destination.Lat],
      DistanceUnit: Distance_Unit,
      IncludeLegGeometry: true,
      TravelMode: Travel_Mode, //Car | Truck | Walking | Bicycle | Motorcycle
      CarModeOptions: {
        AvoidFerries: true,
        AvoidTolls: false,
      },
    });
    const RouteData = response.data;
    setMapRouteData(RouteData);
  };

  const handleSubmitForm = (values, { setSubmitting, resetForm }) => {
    setBookingId(null)
    //console.log(values);
    // Handle form submission 
    const URL = `${API_END_POINT.API_URL}/booking-request`
    axios.post(URL, { ...values, Mobile: values.CountryCode + values.Mobile, Source: place, Destination: destination, DurationSeconds: MapRouteData.Summary.DurationSeconds })
      .then(res => res.data)
      .then(data => {
        // reset message
        resetForm({ values: initialFormValues });
        setBookingId(data.ride.BookingId)
        setSelectedDateTime(null);
        // setPlace(null);
        // SetDestination(null); 

        // show success message
        showToast('Ride Created');

        setSubmitting(false);
      })
      .catch(error => {
        // show error message
        showToast('Some error occured', 'error');

        setSubmitting(false);
      });

  };
  const resetLocation = () => {
    this.Source.value = '';
  }
  const initialFormValues = {
    Source: '',
    Destination: '',
    Email: isAuthenticated ? user.EmailId : '',
    Mobile: '',
    DepartureOrArrive: 'Departure',
    DateTime: '',
    FirstName: isAuthenticated ? user.FirstName : '',
    LastName: isAuthenticated ? user.LastName : '',
    MPTP: '',
    NDIS: '',
    Comments: '',
    CountryCode: '+61',
  }

  return (
    <AppLayout>
      <Container fluid>
        <Row style={{ margin: "0px" }}>
          <Col xl={"6"} md={"6"}>
            <Box my={10}>
              <Formik
                initialValues={initialFormValues}
                validationSchema={Yup.object().shape({
                  Source: Yup.string().required('Rider pickup location is required'),
                  Destination: Yup.string().required('Rider drop off location is required'),
                  Email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Rider email is invalid').required('Rider email is required'),
                  Mobile: Yup.string().required('Rider mobile is required')
                    // .matches(phoneRegExp, 'Contact number is not valid')
                    .matches(/^(04|4)\d{8,9}$/i, 'Rider mobile is invalid'),
                  CountryCode: Yup.string().required('Country code is required'),
                  DepartureOrArrive: Yup.string().required('Departure/Arrive is required'),
                  DateTime: Yup.date().required('Date/Time is required'),
                  FirstName: Yup.string().required('Rider first name is required')
                    .matches(/^[A-Za-z\d\.'\-\s]{2,50}$/, 'Rider first name is invalid'),
                  LastName: Yup.string().required('Rider last name is required')
                    .matches(/^[A-Za-z\d\.'\-\s]{2,50}$/, 'Rider last name is invalid'),
                  MPTP: Yup.string().matches(/^[\d]{18}$/, 'Rider MPTP should be 18 digit no.'),
                  NDIS: Yup.string().matches(/^[\d]{9}$/, 'Rider NDIS should be 9 digit no.'),
                })}
                onSubmit={handleSubmitForm}
              >
                {({ isSubmitting, handleSubmit, values, setFieldValue, resetForm }) => (
                  <Form onSubmit={handleSubmit}>
                    <Stack
                      direction={'column'}
                      spacing={3}
                      //alignItems={"center"}
                      //justifyContent={"center"}
                      height={"100%"}
                    >
                      {/* Source */}
                      <Field name="Source">
                        {({ field }) => (
                          <Box zIndex={"9999"} display={{ sm: "block", lg: "flex", md: "block" }}
                            width={["100%", "100%", "100%", "100%"]} >
                            <Text mr="2"
                              width={["100%", "100%", "100%", "30%"]}>
                              Rider pickup location:
                            </Text>
                            <Box width={["100%", "100%", "100%", "70%"]} zIndex={"9999"}>
                              <AutoSuggestInput zIndex={"9999"} title='Source' name="Select rider pick up location" setPlace={setPlace} onChangeInput={e => setFieldValue('Source', e)} resetForm={values.Source === ''} />
                              <ErrorMessage name="Source" >
                                {(msg) => (
                                  <Box mt={2}>
                                    <Text color="red" fontSize='13px'>{msg}</Text>
                                  </Box>
                                )}
                              </ErrorMessage>
                            </Box>
                          </Box>
                        )}
                      </Field>
                      {/* Destination */}
                      <Field name="Destination">
                        {({ field }) => (
                          <Box display={{ sm: "block", lg: "flex", md: "block" }}
                            width={["100%", "100%", "100%", "100%"]}   >
                            <Text mr="2"
                              width={["100%", "100%", "100%", "30%"]}>Rider drop off Location:</Text>
                            <Box width={["100%", "100%", "100%", "70%"]} zIndex={"999"}>
                              <AutoSuggestInput name="Select rider drop off location" title='Destination' setPlace={SetDestination} onChangeInput={e => setFieldValue('Destination', e)} resetForm={values.Destination === ''} />
                              <ErrorMessage name="Destination" >
                                {(msg) => (
                                  <Box mt={2}>
                                    <Text color="red" fontSize='13px'>{msg}</Text>
                                  </Box>
                                )}
                              </ErrorMessage>
                            </Box>
                          </Box>
                        )}
                      </Field>

                      <Stack direction={'row'} w={'100%'}
                        display={{ sm: "block", lg: "flex", md: "block" }}
                      >
                        <Box width={["100%", "100%", "100%", "30%"]} >
                          <RadioGroup
                            onChange={(value) => setFieldValue("DepartureOrArrive", value)}
                            value={values.DepartureOrArrive}
                          >
                            <Stack direction="row" justifyContent={"space-around"}>
                              <Radio value="Departure" fontSize={'10px'}>Depart At</Radio>
                              <Radio value="Arrive">Arrive By</Radio>
                            </Stack>
                          </RadioGroup>
                          <ErrorMessage name="DepartureOrArrive" >
                            {(msg) => (
                              <Box mt={2}>
                                <Text color="red" fontSize='13px'>{msg}</Text>
                              </Box>
                            )}
                          </ErrorMessage>
                        </Box>
                        <Box width={["100%", "100%", "100%", "70%"]}>
                        <Box width={["100%"]}
                          padding='10px'
                          fontSize='17px'
                          border='1px solid #e2e8f0'
                          borderRadius='8px'
                          zIndex={"99"} >
                          <InputGroup >
                            <Flatpickr
                              id="DateTime"
                              name="DateTime"

                              value={values.DateTime}
                              placeholder="Select Date | Time"
                              options={{
                                dateFormat: 'Y-m-d H:i',
                                position: 'auto left',
                                minDate: new Date(Date.now() + (3600 * 1000 * 24)),
                                enableTime: true
                                //  onChange: (selectedDates, dateStr, instance) => setDate2(dateStr)
                              }}
                              className="w-100 small"
                              onChange={(selectedDates, dateStr, instance) => {       //  formik.handleChange      
                                //  setDate2(dateStr)
                                values.DateTime = dateStr;
                              }}
                            />

                          </InputGroup> </Box>
                          <Text mb='0px' fontSize='14px' color='#b1b1b1'>Lumi Ride is a pre-booked service. 24 hour notice period required to book a ride</Text>
                          <ErrorMessage name="DateTime" >
                        {(msg) => (
                          <Box mt={2}>
                            <Text color="red" fontSize='13px'>{msg}</Text>
                          </Box>
                        )}
                      </ErrorMessage>
                       </Box>
                      </Stack>
                      
                           
                       
                       
                      <Box display={{ sm: "block", lg: "flex", md: "block" }}
                        width={["100%", "100%", "100%", "100%"]} >
                        <Text mr="2"
                          width={["100%", "40%", "100%", "30%"]}>Rider first name:</Text>
                        <Box width={["100%", "100%", "100%", "70%"]}>
                          <Input
                            // borderRadius="25px"
                            type="text"
                            id="FirstName"
                            name="FirstName"
                            placeholder="Rider first name"
                            className="w-100 small"
                            autoComplete="true"
                            value={values.FirstName}
                            onChange={e => setFieldValue('FirstName', e.target.value)}
                          />
                          <ErrorMessage name="FirstName" >
                            {(msg) => (
                              <Box mt={2}>
                                <Text color="red" fontSize='13px'>{msg}</Text>
                              </Box>
                            )}
                          </ErrorMessage>
                        </Box>
                      </Box>
                      <Box display={{ sm: "block", lg: "flex", md: "block" }}
                        width={["100%", "100%", "100%", "100%"]} >
                        <Text mr="2"
                          width={["100%", "100%", "100%", "30%"]}>Rider last name:</Text>
                        <Box width={["100%", "100%", "100%", "70%"]}>
                          <Input
                            // borderRadius="25px"
                            type="text"
                            id="LastName"
                            name="LastName"
                            placeholder="Rider last name"
                            className="w-100 small"
                            autoComplete="true"
                            value={values.LastName}
                            onChange={e => setFieldValue('LastName', e.target.value)}
                          />
                          <ErrorMessage name="LastName" >
                            {(msg) => (
                              <Box mt={2}>
                                <Text color="red" fontSize='13px'>{msg}</Text>
                              </Box>
                            )}
                          </ErrorMessage>
                        </Box>
                      </Box>

                      <Box display={{ sm: "block", lg: "flex", md: "block" }}
                        width={["100%", "100%", "100%", "100%"]} >
                        <Text mr="2"
                          width={["100%", "100%", "100%", "30%"]}>Rider mobile:</Text>

                        <Box width={["100%", "100%", "100%", "70%"]}>
                          <Stack direction={'row'}>

                            <Box w={'25%'}>
                              <Field name="CountryCode">
                                {({ field }) => (
                                  <Select {...field} placeholder="Select Country Code">
                                    {/* Add options for country codes */}
                                    <option value="+61" selected>+61</option>
                                    <option value="+91">+91</option>
                                    {/* Add more country codes as needed */}
                                  </Select>
                                )}
                              </Field>
                              <ErrorMessage name="CountryCode" >
                              {(msg) => (
                                <Box mt={2}>
                                  <Text color="red" fontSize='13px'>{msg}</Text>
                                </Box>
                              )}
                            </ErrorMessage>
                            </Box>
                            <Box w={'75%'}>
                              <Input
                                // borderRadius="25px"
                                type="tel"
                                id="Mobile"
                                name="Mobile"
                                variant='outline'
                                placeholder="Rider mobile"
                                className="w-100 small"
                                autoComplete="true"
                                value={values.Mobile}
                                onChange={e => setFieldValue('Mobile', e.target.value)}
                              />
                              <Text mb='0px' fontSize='14px' color='#b1b1b1'> Mobile number should start with 04</Text>

                             
                            <ErrorMessage name="Mobile" >
                              {(msg) => (
                                <Box mt={2}>
                                  <Text color="red" fontSize='13px'>{msg}</Text>
                                </Box>
                              )}
                            </ErrorMessage>

                            </Box>

                          </Stack>
                          <Stack direction={'row'} width={'80%'}>

                            
                          </Stack>
                        </Box>
                      </Box>

                      <Box display={{ sm: "block", lg: "flex", md: "block" }}
                        width={["100%", "100%", "100%", "100%"]} >
                        <Text mr="2"
                          width={["100%", "100%", "100%", "30%"]}>Rider email:</Text>
                        <Box width={["100%", "100%", "100%", "70%"]}>
                          <Input type="email" value={values.Email} onChange={e => setFieldValue('Email', e.target.value)} id="Email" name="Email" placeholder="Rider email" variant='outline' className="w-100 small" autoComplete="true" />
                          <ErrorMessage name="Email" >
                            {(msg) => (
                              <Box mt={2}>
                                <Text color="red" fontSize='13px'>{msg}</Text>
                              </Box>
                            )}
                          </ErrorMessage>
                        </Box>
                      </Box>

                      <Box display={{ sm: "block", lg: "flex", md: "block" }}
                        width={["100%", "100%", "100%", "100%"]} >
                        <Text mr="2"
                          width={["100%", "100%", "100%", "30%"]}>Rider MPTP:</Text>
                        <Box width={["100%", "100%", "100%", "70%"]}>
                          <Input
                            // borderRadius="25px"
                            type="text"
                            id="MPTP"
                            name="MPTP"
                            placeholder="Rider MPTP (optional)"
                            className="w-100 small"
                            autoComplete="true"
                            value={values.MPTP}
                            onChange={e => setFieldValue('MPTP', e.target.value)}
                          />
                          <ErrorMessage name="MPTP" >
                            {(msg) => (
                              <Box mt={2}>
                                <Text color="red" fontSize='13px'>{msg}</Text>
                              </Box>
                            )}
                          </ErrorMessage>
                        </Box>
                      </Box>
                      <Box display={{ sm: "block", lg: "flex", md: "block" }}
                        width={["100%", "100%", "100%", "100%"]} >
                        <Text mr="2"
                          width={["100%", "100%", "100%", "30%"]}>Rider NDIS:</Text>
                        <Box width={["100%", "100%", "100%", "70%"]}>
                          <Input
                            // borderRadius="25px"
                            type="text"
                            id="NDIS"
                            name="NDIS"
                            placeholder="Rider NDIS (optional)"
                            className="w-100 small"
                            autoComplete="true"
                            value={values.NDIS}
                            onChange={e => setFieldValue('NDIS', e.target.value)}
                          />
                          <ErrorMessage name="NDIS" >
                            {(msg) => (
                              <Box mt={2}>
                                <Text color="red" fontSize='13px'>{msg}</Text>
                              </Box>
                            )}
                          </ErrorMessage>
                        </Box>
                      </Box>
                      <Flex align="center" w={'100%'}>
                        <Field name="Comments">
                          {({ field }) => (
                            <Textarea
                              {...field}
                              rows={3}
                              className="w-100 small"
                              placeholder="Comments"
                            />
                          )}
                        </Field>
                      </Flex>

                      <Stack
                        spacing={3}
                        direction={'row'}
                        justifyContent={'space-around'}
                        style={{ marginBottom: "50px", marginTop: "20px" }}
                      >


                        {/* <Button
                          colorScheme='teal' 
                          size="sm"
                          disabled={!destination}
                        >
                          Pay
                        </Button> */}
                        <Button
                          colorScheme='teal'
                          bgColor="#000"
                          color='#fff'
                          size="sm"
                          type="button"
                          onClick={() => {
                            resetForm({ values: initialFormValues });
                            setSelectedDateTime(null);
                            // setPlace(null);
                            // setFieldValue('Source', '')
                            // resetLocation(null);
                          }}
                        >
                          Clear Form
                        </Button>
                        <Button type="submit" bgColor="#000"
                          color='#fff' colorScheme='teal' size='sm' disabled={isSubmitting} onClick={handleSubmit}>
                          Send Booking Request
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Box>
          </Col>
          <Col xl={"6"} md={"6"} 
          >
            <Box id="map" my={8} height={'50vh'}
            ></Box>
            {MapRouteData &&
              <Flex
                direction={"row"}
                alignItems={"center"}
                justifyContent={'space-around'}
                marginTop={'3rem'}
                marginBottom={'3rem'}
              >
                <Text>
                  <b> Duration: </b> {convertSecondsToTime(MapRouteData.Summary.DurationSeconds)}
                </Text>
                <Text>
                  <b> Distance : </b> {MapRouteData.Summary.Distance} Km
                </Text>
              </Flex>
            }
            {BookingId && (
              <Alert status='success'>
                <AlertIcon />
                Booking request submitted. This is your Booking Id: {BookingId} .
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </AppLayout>
  );
};

export default BookingRequest;
