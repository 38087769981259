const config = {
    AWS_KEY : 'v1.public.eyJqdGkiOiIzMDI4NzYyMC04YzkxLTRjNzctOWY3ZC04MThmOTI0ODE2ZWQifV2vMbhVpTRF8lppqi9OJQTXuRWMokPjs8njCoYihRPWGNnMtI5QzaudHx9FxuZlcfBccgv0LQQvYKvK0bjTh_celw5tepDaeVs5lv4TMjVwjfQE7-Fo0yCPwXwgwgpKQ41tfBTCrNM5Mpw_d0yYzwbKQlizPTTid5-qczOsfPpae0yna0dctjr2VfTgJPoFXorvIVyKyJyVJO8RuNyFOkmMN0xob-MYDPbmScsRE_2hKv-ZXStXTZIgkkdzD2eMkJguOxOBPFsKEl_4jpcahYFQXnId5DTGMjgokjpu25zsVJdSYoJtHZqQV8CG5ru2dVuPHjVFZaFhEUTQ2Kxsgl0.ZTA2OTdiZTItNzgyYy00YWI5LWFmODQtZjdkYmJkODNkMmFh',
    AWS_POOL_ID: 'ap-southeast-2:f4169560-acf5-466c-bf47-1eac74627c22',
    Distance_Unit: "Kilometers",
    Travel_Mode: "Car",
    API_END_POINT: {
         API_URL: "https://api.lumiride.com/api", // Production
        // API_URL: "https://www.dev-api.lumiride.com/api", // dev
        ROUTE_CALCULATION : "https://routes.geo.ap-southeast-2.amazonaws.com/routes/v0/calculators/riderroutecalculator/calculate/route",
        FETCH_SUGGETIONS : "https://places.geo.ap-southeast-2.amazonaws.com/places/v0/indexes/LumiPlaceIndex/search/suggestions",
        FETCH_LOCATIONS : "https://places.geo.ap-southeast-2.amazonaws.com/places/v0/indexes/LumiPlaceIndex/places",
        LOAD_MAP: "https://maps.geo.ap-southeast-2.amazonaws.com/maps/v0/maps/Lumiride/style-descriptor",
    }
}

export default config
