/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:0ef5d56f-df79-480d-8ed9-63b2f7c9e457",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_Mr5h8rWFH",
    "aws_user_pools_web_client_id": "2rgob8t5s98j9o6pss6t744djh",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "geo": {
        "amazon_location_service": {
            "region": "ap-southeast-2",
            "maps": {
                "items": {
                    "mapeded208c-prod": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "mapeded208c-prod"
            },
            "search_indices": {
                "items": [
                    "placeIndexfb9db95e-prod"
                ],
                "default": "placeIndexfb9db95e-prod"
            }
        }
    }
};


export default awsmobile;
